import React from 'react';
import { Router, routes, renderRoutes } from 'routes/index';
import './App.less';

const authed = true; // 登陆后可通过 redux 修改
const authPath = '/login';

function App() {
  // rem自适应设置
  let resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
  let reCalc = function () {
    //设置根字体大小npm
    let docEl = document.getElementsByTagName('html')[0];
    // console.log(docEl)
    docEl.style.fontSize = 20 * (docEl.clientWidth / 375) + 'px';
    console.log(resizeEvt);
  };
  document.addEventListener(
    'DOMContentLoaded',
    function () {
      reCalc();
    },
    false,
  );

  window.addEventListener(resizeEvt, reCalc, false);
  return (
    <div className="App">
      <Router>{renderRoutes(routes, authed, authPath)}</Router>
    </div>
  );
}

export default App;
