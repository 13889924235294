import React, { lazy } from 'react';
import { RouteConfig } from './renderRoutes';

let type = '';
if (window.location.href.indexOf('smartdev-console') >= 0) {
  type = '/detail';
} else {
  type = '/index';
}

const routes: RouteConfig[] = [
  {
    path: '/',
    requireAuth: true,
    hasPermission: true,
    root: true,
    // component: lazy(() => import('layouts/BlankLayout')),
    component: lazy(() => import('@/pages/officialWebsite/index')),
    routes: [
      {
        name: '首页',
        path: '/',
        exact: true,
        hasPermission: true,
        // root: true,
        // render: () => <Redirect to={type} />,
        component: lazy(() => import('@/pages/homePage/index')),
      },
      // {
      //   name: '首页',
      //   path: '/index',
      //   exact: true,
      //   hasPermission: true,
      //   component: lazy(() => import('@/pages/homePage/index')),
      // },
      {
        name: '片区贸易概况',
        path: '/tradeDetail',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/tradeDetail/index')),
      },
      {
        name: '特色服务',
        path: '/service',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/service/index')),
      },
      {
        name: '指数与咨询',
        path: '/consulting',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/consulting/index')),
      },
      {
        name: '指数与咨询详情页',
        path: '/consulting/detail',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/consulting/detail')),
      },
      {
        name: '数据开放平台',
        path: '/openData',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/openData/index')),
      },
      {
        name: '数据开放平台详情页',
        path: '/openData/detail',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/openData/detail')),
      },
      {
        name: '联系我们',
        path: '/callMe',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/callMe/index')),
      },
      {
        name: '登录',
        path: '/login',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/login/index')),
      },
      {
        name: '查看更多',
        path: '/watchmore',
        exact: true,
        hasPermission: true,
        component: lazy(() => import('@/pages/watchmore/index')),
      },
    ],
  },
];

export default routes;
